//import statements
import focus from '@alpinejs/focus';
import alpine from 'alpinejs';
// import { CookieService } from './extra/CookieService';

// import { stickyBar } from '@scripts/front-end/desktop/sticky-product';
// import { draggableScroll } from '@scripts/front-end/desktop/draggable-scroll';
// const axios = require('axios');

//plugin initialization
alpine.plugin(focus)
Object.assign(window, { Alpine: alpine }).Alpine.start();
import.meta.webpackHot?.accept(console.error);

document.addEventListener("DOMContentLoaded", function () {
  //expand control functionality
  const userSelection = document.querySelectorAll('.expand-contol-js');

  userSelection.forEach(function (element) {
    element.addEventListener("click", toggleActiveClass);
  });

  //intersection observer functionality
  const callback = function (entries) {
    entries.forEach((entry) => {
      if (!entry.target.classList.contains('animate-fadeIn')) {
        if (entry.isIntersecting) {
          entry.target.classList.add("animate-fadeIn");
        }
      }
    });
  };
  const observer = new IntersectionObserver(callback);
  const targets = document.querySelectorAll(".js-show-on-scroll");
  targets.forEach(function (target) {
    target.classList.add('opacity-0', 'translate-y-6');
    observer.observe(target);
  });

  // FAQ toggle functionality
  const faqEl = document.querySelector('.faq-el');
  if (faqEl !== null) {
    faqEl.addEventListener('click', function () {
      faqEl.classList.toggle('open');
    });
  }

  //exit popup functionality
  // const exit = e => {
  //   const shouldExit =
  //     [...e.target.classList].includes('exit-intent-popup') ||
  //     e.target.className === 'close' ||
  //     e.keyCode === 27;
  //   if (shouldExit) {
  //     document.querySelector('.exit-intent-popup').classList.remove('visible');
  //   }
  // };
  // const mouseEvent = e => {
  //   const shouldShowExitIntent =
  //     !e.toElement &&
  //     !e.relatedTarget &&
  //     e.clientY < 10;
  //   if (shouldShowExitIntent) {
  //     document.removeEventListener('mouseout', mouseEvent);
  //     document.querySelector('.exit-intent-popup').classList.add('visible');
  //     CookieService.setCookie('exitIntentShown', true, 30);
  //   }
  // };
  // if (!CookieService.getCookie('exitIntentShown')) {
  //   setTimeout(() => {
  //     document.addEventListener('mouseout', mouseEvent);
  //     document.addEventListener('keydown', exit);
  //     document.querySelector('.exit-intent-popup').addEventListener('click', exit);
  //   }, 0);
  // }
});


function toggleActiveClass() {
  this.classList.toggle("active");
}
